<template>
  <ul class="member-cards__list">
    <li v-for="pm in paymentMethods" :key="pm.id" class="member-cards__card member-card">
      <div class="member-card__data">
        <PaymentDetailsCardBrand :brand="pm.card.brand" />
        <span v-if="pm.card.last4" class="member-card__text text-value">ending with {{ pm.card.last4 }}</span>
      </div>

      <div class="member-card__controls">
        <span v-if="pm.isDefault" class="member-card__default-mark">Default</span>
        <button
          v-else
          class="member-card__set-default-btn"
          :class="{ 'btn-active-state': pm.id === changingDefaultId }"
          :disabled="disableButtons"
          type="button"
          @click="$emit('changing-default', pm.id)"
        >
          <span v-if="pm.id !== changingDefaultId">Set as default</span>
          <AppLoader v-else :border-width="2" class="add-card-form__loader" :margin="0" :size="14" />
        </button>

        <button
          v-if="!pm.isDefault || paymentMethods.length === 1"
          class="member-card__delete-btn"
          :class="{ 'btn-active-state': pm.id === deletingId }"
          :disabled="disableButtons"
          type="button"
          @click="$emit('deleting', pm)"
        >
          <AppLoader
            v-if="showLoaderOnDeleting && pm.id === deletingId"
            :border-width="2"
            class="add-card-form__loader"
            :margin="0"
            :size="14"
          />
          <TheTrashIcon v-else class="member-card__delete-btn-icon" />
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
import PaymentDetailsCardBrand from '@/components/partials/profile/PaymentDetailsCardBrand';
import TheTrashIcon from '@/components/icons/TheTrashIcon.vue';

export default {
  name: 'PaymentMethods',
  components: { TheTrashIcon, PaymentDetailsCardBrand },
  props: {
    changingDefaultId: {
      type: String,
      default: null,
    },
    deletingId: {
      type: String,
      default: null,
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
    showLoaderOnDeleting: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
