<template>
  <div class="add-card-form__input-container">
    <PaymentMethodFormLabel :for="`input-${id}`" :label="label" :move-to-up="!!value" />
    <input
      :id="`input-${id}`"
      class="add-card-form__input"
      :type="type"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="$forceUpdate()"
    />
    <PaymentMethodFormError :error="error" />
  </div>
</template>

<script>
import PaymentMethodFormLabel from '@/components/partials/profile/PaymentMethodFormLabel';
import PaymentMethodFormError from '@/components/partials/profile/PaymentMethodFormError';

export default {
  name: 'PaymentMethodFormInput',
  components: { PaymentMethodFormLabel, PaymentMethodFormError },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
