import apiProtectedRequest from '@/api/apiProtectedRequest';
import { AxiosError, AxiosResponse } from 'axios';

export async function createPaymentSetupIntent() {
  return new Promise<AxiosResponse>((resolve, reject) => {
    apiProtectedRequest
      .post('payment/setup-intent')
      .then((res: AxiosResponse) => {
        resolve(res.data);
      })
      .catch(async (err: AxiosError) => {
        reject(err.response?.data || { message: 'Something went wrong.' });
      });
  });
}

export async function updateDefaultPaymentMethod(id: string) {
  return new Promise<AxiosResponse>((resolve, reject) => {
    apiProtectedRequest
      .put(`payment/default-method/${id}`)
      .then((res: AxiosResponse) => {
        resolve(res.data);
      })
      .catch(async (err: AxiosError) => {
        reject(err.response?.data || { message: 'Something went wrong.' });
      });
  });
}

export async function deletePaymentMethod(id: string) {
  return new Promise<AxiosResponse>((resolve, reject) => {
    apiProtectedRequest
      .delete(`payment/method/${id}`)
      .then((res: AxiosResponse) => {
        resolve(res.data.data);
      })
      .catch(async (err: AxiosError) => {
        reject(err.response?.data || { message: 'Something went wrong.' });
      });
  });
}
