<template>
  <div
    class="member-card__brand"
    :class="{ 'member-card__brand--default': isDefault }"
    :style="`background-image:url(${require(`@/assets/images/icons/payments/icon-${iconFileName}.svg`)})`"
  />
</template>

<script>
import cardBrands from '@/models/cardBrands';

export default {
  name: 'PaymentDetailsCardBrand',
  props: {
    brand: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconFileName() {
      if (cardBrands.includes(this.brand)) {
        return this.brand.toLowerCase().replace(/\s+/g, '-');
      }
      return 'default';
    },
    isDefault() {
      return this.iconFileName === 'default';
    },
  },
};
</script>
